<template>
  <div class="flex justify-center mt-2 text-3xs font-bold">
    <div
      v-if="rtp > 0 || hasLiveRtp"
      class="w-[43px] mr-1 flex justify-center justify-self-end items-center text-center h-4 text-[11px] cursor-pointer rounded-md px-1.5 xs:w-[65px] xs:h-[18px] xs:text-[12px] sm:h-5"
      :class="rtpClasses"
      @click="showInfoModal"
    >
      <span v-if="hasLiveRtp" class="hidden mr-1 line-height xs:block">
        ⚡
      </span>
      <span v-else class="hidden mr-1 line-height xs:block">
        🖥️
      </span>
      <div class="flex line-height">
        <span

          class="line-height"
        >
          {{ rtpValue }}%
        </span>
      </div>
    </div>
    <div
      v-if="showVolatility"
      class="w-[43px] flex justify-center justify-self-start items-center py-1 px-1 rounded-md h-4 cursor-pointer bg-slate-border xs:w-[48px] xs:h-[18px] sm:px-1 sm:h-5"
      @click="openVolatilityModel"
    >
      <div class="w-full flex justify-center h-4 items-center pb-[1px] gap-1">
        <span class="hidden xs:block">
          🌋
        </span>
        <img
          :src="volatilityIcon"
          class="inline-block mb-[-1px]"
          width="20"
          height="16"
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import VolOne from '~/assets/img/volatility/volatility-1-min.svg';
import VolTwo from '~/assets/img/volatility/volatility-2-low.svg';
import VolThree from '~/assets/img/volatility/volatility-3-medium.svg';
import VolFour from '~/assets/img/volatility/volatility-4-high.svg';
import VolFive from '~/assets/img/volatility/volatility-5-max.svg';

const props = defineProps({
  liveRtp: {
    type: Number,
    default: null,
  },
  rtp: {
    type: Number,
    default: null,
  },
  showLiveRtp: {
    type: Boolean,
    default: false,
  },
  volatility: {
    type: Number,
    default: 0,
  },
  showVolatility: {
    type: Boolean,
    default: false,
  },
});

const uiStore = useUiStore();
const { showVolatilityModal, } = storeToRefs(uiStore);
const { openInfoCmsModal, } = uiStore;

const hasLiveRtp = computed(() => !!props.liveRtp && props.showLiveRtp);

const rtpClasses = computed(() => {
  if (!props.rtp && !hasLiveRtp.value) {
    return '';
  }

  const activeRtp = props.showLiveRtp && props.liveRtp && props.liveRtp !== props.rtp ? props.liveRtp : props.rtp;

  if (activeRtp >= 96) {
    return 'text-green-500' + (hasLiveRtp.value ? ' bg-green-800' : '');
  }

  if (activeRtp >= 95 && props.rtp < 96) {
    return 'text-yellow-400' + (hasLiveRtp.value ? ' bg-yellow-900' : '');
  }

  if (activeRtp < 95) {
    return 'text-yellow-400' + (hasLiveRtp.value ? ' bg-orange-900' : '');
  }
});

const volatilityIcon = computed(() => {
  const icons = [VolOne, VolTwo, VolThree, VolFour, VolFive,];
  const index = props.volatility <= 1 ? 0 : props.volatility - 1;

  return icons[index];
});

const rtpValue = computed(() =>
  props.showLiveRtp && props.liveRtp && props.liveRtp !== props.rtp
    ? props.liveRtp.toFixed(1)
    : props.rtp.toFixed(1)
);

function showInfoModal() {
  if (hasLiveRtp.value) {
    return;
  }

  openInfoCmsModal({ modalId: 'RtpInfo', });
}

function openVolatilityModel() {
  showVolatilityModal.value = true;
}
</script>

<style scoped lang="scss">
.line-height {
  line-height: normal;
}
</style>
